// Grid settings
$enable-flex: true;
$main-sm-columns: 12;
$sidebar-sm-columns: 4;

// Colors
$brand-primary: #99cc00;

// Grid containers
//

// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  xl: 1164px
) !default;