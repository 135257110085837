body {
  font-family: 'Merriweather';
  background-attachment: fixed;
  font-size: 0.9rem;
}

a, a:hover, a:visited {
    color: $brand-primary;
    text-decoration: none;
}

.main {
    padding-left: 48px !important;
    padding-right: 30px !important;
}
.sidebar {
    padding-left: 30px !important;
    padding-right: 48px !important;
}

#main-wrapper.container {
  padding:0;
  -moz-box-shadow: 0px 8px 32px rgb(100,100,100);
  -webkit-box-shadow: 0px 8px 32px rgb(100,100,100);
  box-shadow: 0px 8px 32px rgb(100,100,100);
  background-color: #fff;
}

#socials {
  background-color: #fff;
  margin: 0;
  font-size: 28px;
  height: 38px;

  div {
    text-align: right;
    span {
      font-family: 'Catamaran';
      font-size: 16px;
    }
    a.facebook { color: #aeaeae; }
    a.twitter { color: #aeaeae; }
    a.youtube { color: #aeaeae; }
  }
}

.container {
    padding:0 15px 0 15px;
}

header.banner {
  background-image: url(../images/pattern-fondo.png);
  background-attachment: fixed;

  img {
    width: 100%;
  }
}

header div.container { padding:0; }

.navbar { border-radius: 0 !important; }

header nav.navbar { padding: 0; }

header nav.bg-faded { background-color: #99cc00; }

#menu-principale {
  li {
    line-height: 2.5;
    margin-left: 25px;
  }

  a {
    color: #fff;
    padding: 6px 8px;
    font-size: 11pt;
    display: block;
    width: 100%;
    text-decoration: none;
  }

  a:hover, li.active > a {
    background-color: #fff;
    color: #000;
    text-decoration: none;
  }
  .dropdown-menu {
      margin-top: 0;
      background-color: $brand-primary !important;
      border-radius: 0;
      border: none;
      padding: 0;

      li {
        line-height: 1.5;
        width: 100%;
        display: block;
        margin-left: 0;
      }
  }
}

.wrap.container { margin-bottom: 20px; }

.content.row { padding-top: 33px; }

.entry-title {

  a {
    font-family: 'Catamaran';
    font-size: 30px;
    color: #333;
    text-transform: uppercase;
  }
  a:hover { color: $brand-primary; }
}

.entry-content { font-size: 12pt; }

h1,
h2,
h3,
h4,
h5,
.nav
{
  font-family: 'Catamaran';
  font-weight: 600;
}

span[property=itemListElement] {
  font-family: 'Catamaran';
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: #666;
}

.home {

  article {
      margin-top: 0;

    header {
      border-top: 2px solid #cecece;
      border-bottom: 2px solid #cecece;
      margin-bottom: 10px;
    }

    .entry-content {
      margin: 10px 0;
    }
  }
}

.single {

  article {
    margin-top: 10px;

    header {
      padding: 10px 0;
      margin-bottom: 30px;

      h4 {
        font-size: 14px;
      }

      h1 {
        font-size: 30px;
      }


    }
  }
}

article {
  margin: 30px 0;
  clear: both;

  .entry-summary, .entry-content {
    /*clear: both;*/
    font-family: Merriweather;
    font-size: 13px;
    color: #666;
  }

  footer a {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.blog article .img-fluid { float: right; }

footer {
  font-family: 'Catamaran';
  font-weight: 500;
  font-size: 12px;
  color: #fff;

  .container {
    background-color: #333;
    padding: 20px;
  }

}

footer h3 {
    font-size: 18px;
}

footer a.social {
    font-size: 28px;
    margin: 5px;
    color: #fff;
}

footer div.social{
    clear: both;
    text-align: right;
    font-size: 12px;

    a, a:hover, a:visited {
        color: #fff;
    }
}


.sticky.is-sticky {
  position: fixed;
  /*left: 0;*/
  /*right: 0;*/
  top: 0;
  z-index: 1000;
  /*width: 100%;*/
}

aside .carousel{
    margin-bottom: 30px;
}
.carousel-caption {
    display: none;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: left;
    padding-left: 30px;
    opacity: .6;
    background-color: #000;
    padding-bottom: 0;

    h3 {
        font-size: 12pt;
        text-transform: uppercase;
    }
    a, a:hover {
        color: #fff;
    }
}

.carousel-indicators {
    display: none;
    right: 30px;
    width: auto;
    margin-left: 0;
    left: 0;
    text-align: right;

    .active {
        background-color: $brand-primary;
        border-color: $brand-primary;
    }
}

.carousel-item {
  img {
    width: 100%;
  }
}

.carousel-fade .carousel-inner .carousel-item {
	-webkit-transition-property: opacity;
	-moz-transition-property: opacity;
	-o-transition-property: opacity;
	-ms-transition-property: opacity;
	transition-property: opacity;
}
.carousel-fade .carousel-inner .carousel-item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
	opacity: 0;
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
	opacity: 1;
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
	left: 0;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

main {
  border-right: 1px solid #c2c2c2;
}

aside {

    text-align: center;

  section.widget_text {
    font-style: italic;
    width: 80%;
    position:relative;
    padding: 30px 0 30px 50px;
  }
  section.widget_text:before {
    content: "\f10d";
    font-family: FontAwesome;
    position:absolute;
    top:0;
    left: 10px;
    font-size: 32px;
    color: #ddd;
  }

  h5 {
    font-style: italic;
    margin-top: 50px;
  }

  .card {
    margin-bottom: 0;
    border-radius: 0;
  }
}

#evento-mappa { height: 348px; }

.blog article {
    border-top: 2px solid #cecece;
    padding-top: 5px;
}

header, .page-header {
    border-top: 2px solid #cecece;
    border-bottom: 2px solid #cecece;
}

.blog article header {
    border: none !important;
}

.page-header {
    padding-top: 10px;
}

.home .page-header{
    padding-top: 0;
    border: none !important;
}

aside section {
    text-align: left;
}

.entry-content, .content main p {
    line-height: 21px;
    margin-bottom: 0px;
}

#banner-logo {
    height: 190px;

    a {
        margin-left: 200px;
    }
}

#navbarNav {
    margin-right: 48px;
}

article.evento {
    h1, h2, h3, h4, h5 {
        margin-bottom: 0;
    }
}

article.post {
    clear: both;
}

.gallery img { margin-bottom: 15%; }

.carousel .label {
    position: absolute;
    z-index: 1000;
    right: 0;
    background-color: $brand-primary;
    color: #fff;
    width: 104px;
    height: 26px;
    font-family: 'Catamaran';
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 5px;
    text-align: center;
}

#accordion {
    font-family: 'Catamaran';
    text-align: left;
}

.card-header h6 {
    margin-bottom: 0;
}

#accordion p.active a {
    font-style: italic;
    font-weight: bold;
    text-decoration: underline;
}

p.archivio {
    margin: 10px;
}

div.content-column { padding: 0 15px; }

body.eventi {
    div.years-nav {
        a {
            color: #000;
        }
    }

    article.evento {
        header {
            position: absolute;
            width: 350px;
            right:30px;
            padding:10px;
            background-color:#ececec;
            opacity:0.8;

            h1 { font-size: 26px; margin-bottom: 10px; }
            h3 { font-size: 16px; text-transform: uppercase; }
            h4 { font-size: 18px; }
            h5 { font-size: 12px; margin-bottom: 10px; }
            h6 { font-size: 10px; }
        }

        a {
            color: #000;
        }
    }
}

.year.active { font-weight: bold; }

#yoruba-logo-small {
    width: auto;
}

@media screen and (max-width: 630px) {
    header.banner img {
        width: 100%;
    }

    #banner-logo a {
        margin-left: 0;
    }
}

@media screen and (max-width: 1004px) {
    .row.gallery a {
        width: 50%;
    }
}
